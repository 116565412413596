import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar';

const ГазоИНефтепромысел  = () => (
    <Layout>
      <Helmet>
          <title>Реагенты Газо и Нефтепромысла</title>
      </Helmet>
      <Header 
        styleName='header header-oil'
        title={<span className="text-header-title">Газо и нефтепромысел</span>}/>
      <div className="columns">
        <div className="column content">
          <div className="title-small-nomargintop title-small content-title">Реагенты для газо и нефтепромысла</div>          
          <div className="content-text">Современные технологии IN-ECO™ для нефте- и газопромыслов реализуют возможность оптимального использования ресурсов, снижают экологическую нагрузку, обеспечивают безопасность эксплуатации и продлевают срок службы оборудования, увеличивают производительность и минимизируют эксплуатационные затраты.<br/>
            <br/>
            Компания предлагает широкий ассортимент продуктов и технологических решений для обработки пластовых вод, защиты нефтепромыслового и бурового оборудования, подготовки и транспортировки нефти, улучшение коллекторских свойств и нефтеотдачи пластов:</div>
          <ul>
              <li className="content-text">Ингибиторы коррозии для первичной переработки нефти</li>
              <li className="content-text">Пеногасители и другие продукты для разделения сред нефти, газа и воды</li>
              <li className="content-text">Высокоэффективные биоциды и слимициды</li>
              <li className="content-text">Связыватели агрессивных газов (CO<sub>2</sub> и H<sub>2</sub>S)</li>
              <li className="content-text">Ингибиторы солеотложений</li>
              <li className="content-text">Ингибиторы коррозии для транспортировки необработанной нефти</li>
              <li className="content-text">Деэмульгаторы</li>
          </ul>
        </div>
        <Sidebar
        analysis='Анкеты'
        boiler='Бойлер'
        cooling='Охлаждение'
        ro='Осмос'
        broshure='IN-ECO брошура'
        certificates='Сертификаты'
        russian={true}
        />
      </div>
    </Layout>
)

export default ГазоИНефтепромысел